import React from "react"

import { useShopify } from "@hooks/useShopify"
import { PAYMENT_PLAN_COLUMNS, PAYMENT_TRANSACTION_COLUMNS, PAYMENT_DATE_FORMAT, STATUS_TRANSLATION } from "../CustomerPaymentConsts"

export const withCustomerPaymentsList = Component => ({ name = `CustomerPaymentsList`, items, transaction, onSelectePlan }) => {
  const { formatMoney } = useShopify()
  const columns = transaction ? PAYMENT_TRANSACTION_COLUMNS : PAYMENT_PLAN_COLUMNS

  const handleClick = item => {
    onSelectePlan(item)
  }

  const toTitleCase = str => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const checkComparedValue = (item, field, comparedField) => {
    const value = item?.[field]
    const comparedValue = item?.[comparedField]

    return comparedValue ? comparedValue !== value : false
  }

  Component.displayName = name
  return (
    <Component
      checkComparedValue={checkComparedValue}
      columns={columns}
      formatMoney={formatMoney}
      handleClick={handleClick}
      items={items}
      paymentFormat={PAYMENT_DATE_FORMAT}
      status={STATUS_TRANSLATION}
      toTitleCase={toTitleCase}
    />
  )
}
