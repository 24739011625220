import React from "react"
import { format } from "date-fns"

import { withCustomerPaymentsList } from "./withCustomerPaymentsList"
import { Row } from "../../../Styled/Table"
import { StyledTextButton, PaymentImg, StyledColumn, StyledTag } from "../CustomerPaymentsStyles"

interface Props {
  checkComparedValue: any
  columns: Array<any>
  formatMoney: any
  handleClick: any
  items: Array<any>
  paymentFormat: any
  status: any
  toTitleCase: any
}

export const CustomerPaymentsList = withCustomerPaymentsList(
  ({ checkComparedValue, columns, formatMoney, handleClick, items, paymentFormat, status, toTitleCase }: Props) => (
    <>
      {items?.map(item => (
        <Row key={item.id}>
          {columns.map((column, index) => {
            const value = column.paymentMethod
              ? item?.paypal || item?.creditCard
              : column.comparedField && checkComparedValue(item, column.field, column.comparedField)
              ? item?.[column?.comparedField]
              : item?.[column?.field]

            const formattedValue = value ? (
              column.format ? (
                `${formatMoney(value, null, "${{amount_with_comma_separator}}")}`
              ) : column.date ? (
                format(new Date(value), paymentFormat)
              ) : column.linkText ? (
                <StyledTextButton onClick={() => handleClick(item)} title={column.linkText}>
                  <p>{value}</p>
                </StyledTextButton>
              ) : column.paymentMethod ? (
                <>
                  <PaymentImg src={value.imageUrl} />
                  <p>{`${value.last4 ? `**${value.last4}` : `PayPal`}`}</p>
                </>
              ) : column.styled ? (
                <StyledTag status={value}>{column.status && status[value] ? status[value] : value}</StyledTag>
              ) : (
                toTitleCase(column.status && status[value] ? status[value] : value)
              )
            ) : (
              `–`
            )

            return (
              <StyledColumn key={index} columns={columns.length} first={!column.center}>
                {formattedValue}
              </StyledColumn>
            )
          })}
        </Row>
      ))}
    </>
  )
)
