export const PAYMENT_PLAN_COLUMNS = [
  {
    field: "shopifyOrderName",
    key: "OrderNumber",
    linkText: "Order",
    target: "_blank",
  },
  {
    date: true,
    field: "startDate",
    key: "StartDate",
  },
  {
    date: true,
    field: "endDate",
    key: "EndDate",
  },
  {
    field: "outstanding",
    comparedField: "grossOutstanding",
    key: "Total",
    format: true,
  },
  {
    field: "status",
    key: "Status",
    center: true,
    styled: true,
    status: true,
  },
]

export const PAYMENT_TRANSACTION_COLUMNS = [
  {
    field: "createdAt",
    key: "Date",
    date: true,
  },
  { field: "paymentType", key: "PaymentType" },
  {
    field: "amount",
    key: "Amount",
    format: true,
  },
  {
    paymentMethod: true,
    field: "creditCard",
    digitField: "last4",
    imageField: "imageUrl",
    key: "PaymentMethod",
  },
  {
    field: "status",
    key: "Status",
    center: true,
    status: true,
  },
]

export const PAYMENT_DATE_FORMAT = `dd/MM/yyyy`

export const STATUS_TRANSLATION = {
  Canceled: "Cancelled",
  Expired: "Completed",
  settled: "Processed",
  settling: "Pending",
}
