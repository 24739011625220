import React from "react"

import { StyledPaymentFooterContainer, LockIcon, StyledPaymentFooterText } from "../CheckoutStyles"

export const CheckoutPaymentFooter = ({ condensed, locales }) => (
  <StyledPaymentFooterContainer condensed={condensed}>
    <LockIcon />
    <StyledPaymentFooterText>{locales.checkoutFooter1}</StyledPaymentFooterText>
    <StyledPaymentFooterText>{locales.checkoutFooter2}</StyledPaymentFooterText>
  </StyledPaymentFooterContainer>
)
