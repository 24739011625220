import React from "react"

import { withCustomerPaymentsForm } from "./withCustomerPaymentsForm"
import { RichText } from "../../../RichText/RichText"
import { Dropdown } from "../../../Styled/Dropdown/Dropdown"
import { PrimaryButton } from "../../../Styled/Button"
import {
  Form as StyledForm,
  FormDropdown,
  FormFields,
  FormFluidField,
  FormHeading,
  FormInput,
  FormLabel,
  FormSuccess,
  FormTextarea,
} from "../../../Styled/Form"

interface Props {
  data: any
  fields: any
  handleChange: any
  handleSubmit: any
  loading: boolean
  locales: any
  sent: boolean
  subjects: Array<any>
  title: string
}

export const CustomerPaymentsForm = withCustomerPaymentsForm(
  ({ data, fields, handleChange, handleSubmit, loading, locales, sent, subjects, title }: Props) => (
    <StyledForm onSubmit={handleSubmit}>
      {title ? (
        <FormHeading condensed={`true`}>
          <RichText content={title} />
        </FormHeading>
      ) : null}

      <FormFields>
        {fields.map(field => (
          <FormFluidField key={field.key}>
            {field.type !== `textarea` ? <FormLabel>{locales[field.key]}</FormLabel> : null}

            {field.type === `select` ? (
              <FormDropdown>
                <Dropdown
                  label={!data[field.key] ? locales[field.key] : null}
                  name={field.key}
                  onChange={(value, name) => handleChange({ target: { name, value } })}
                  options={subjects}
                  required={field.required}
                  value={data[field.key]}
                />
              </FormDropdown>
            ) : field.type === `textarea` ? (
              <FormTextarea
                name={field.key}
                onChange={handleChange}
                placeholder={locales[field.placeholder]}
                readOnly={field.readOnly}
                required={field.required}
                value={data[field.key]}
              />
            ) : (
              <FormInput
                name={field.key}
                onChange={handleChange}
                readOnly={field.readOnly}
                required={field.required}
                type={field.type}
                value={data[field.key]}
                lightBorder
              />
            )}
          </FormFluidField>
        ))}

        <FormFluidField>
          <PrimaryButton disabled={loading} full={`true`} type={`submit`}>
            {locales.submit || locales.popupButton1}
          </PrimaryButton>
        </FormFluidField>
      </FormFields>

      {sent ? <FormSuccess>{locales.success}</FormSuccess> : null}
    </StyledForm>
  )
)
