import React from "react"

import { FormFluidField } from "../../Styled/Form"
import { StyledFormInput } from "../CheckoutStyles"

export const CheckoutPaymentMethodField = ({ customerData, field, handleChange, error, locales }) => (
  <FormFluidField width={field.width}>
    <StyledFormInput
      errors={(!customerData[field.key] || customerData[field.key] == "") && error}
      maxLength={field.maxchars}
      id={field.id}
      name={field.key}
      value={customerData[field.key] || ""}
      onChange={handleChange}
      type={`text`}
      placeholder={locales[field.placeholder]}
      readOnly={false}
      required={customerData[field.key]}
    />
  </FormFluidField>
)
