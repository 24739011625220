import React from "react"

import { withCustomerPaymentsMethod } from "./withCustomerPaymentsMethod"
import { CustomerPaymentsUpdatePopup } from "../Popup/CustomerPaymentsUpdatePopup"
import {
  PaymentMethods,
  PaymentMethod,
  PaymentMethodContent,
  PaymentMethodDetails,
  PaymentMethodP,
  PaymentMethodLabel,
  PaymentMethodLinks,
  PaymentMethodButton,
  PaymentImg,
} from "../CustomerPaymentsStyles"

interface Props {
  error: any
  handleModalActiveChange: any
  handleSubmit: any
  modalActive: any
  setModalActive: any
  paymentMethod: any
  onUpdate: any
  updateError: string
  isUpdating: boolean
  reset: any
  updateStatus: any
  locales: any
}

export const CustomerPaymentsMethod = withCustomerPaymentsMethod(
  ({
    error,
    handleModalActiveChange,
    handleSubmit,
    isUpdating,
    locales,
    modalActive,
    paymentMethod,
    setModalActive,
    updateError,
    updateStatus,
  }: Props) => (
    <>
      <PaymentMethods>
        <PaymentMethod key={paymentMethod.token}>
          <PaymentMethodContent>
            <PaymentMethodDetails>
              <PaymentMethodLabel>{paymentMethod.cardType ? paymentMethod.cardType : `PayPal`}&nbsp;</PaymentMethodLabel>
              <PaymentImg src={paymentMethod.imageUrl} />

              {paymentMethod.cardType ? (
                <PaymentMethodP as={`div`}>
                  <p>{paymentMethod.maskedNumber}</p>
                  <p>{paymentMethod.expirationDate}</p>
                </PaymentMethodP>
              ) : (
                <PaymentMethodP as={`div`}>
                  <p>{`${paymentMethod.email}`}</p>
                </PaymentMethodP>
              )}
            </PaymentMethodDetails>

            <PaymentMethodLinks>
              <PaymentMethodButton onClick={() => setModalActive(!modalActive)}>{`Update`}</PaymentMethodButton>
            </PaymentMethodLinks>
          </PaymentMethodContent>
        </PaymentMethod>
      </PaymentMethods>

      <CustomerPaymentsUpdatePopup
        active={modalActive}
        error={updateError || error}
        onActiveChange={handleModalActiveChange}
        onSubmit={handleSubmit}
        isSubmitting={isUpdating}
        updateStatus={updateStatus}
        locales={locales}
      />
    </>
  )
)
