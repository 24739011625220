import React, { useState } from "react"

export const withCustomerPaymentsUpdatePopup = Component => ({
  name = `CustomerPaymentsUpdatePopup`,
  active,
  error,
  isSubmitting,
  onActiveChange,
  onSubmit,
  locales,
  updateStatus,
}) => {
  const [customerData, setCustomerData] = useState({})

  const handleChange = ({ target: { name, value } }) => {
    setCustomerData(prevCustomerData => {
      const newCustomerData = {
        ...prevCustomerData,
      }

      if (name === "cardName" || name === "expirationDate" || (name !== "cardName" && !isNaN(value))) {
        newCustomerData[name] = value
      }

      return newCustomerData
    })
  }

  const handleClickMakePayment = () => {
    onSubmit(customerData)
  }

  const handleSetActive = () => {
    setCustomerData({})
    onActiveChange()
  }

  Component.displayName = name
  return (
    <Component
      active={active}
      customerData={customerData}
      error={error}
      handleChange={handleChange}
      handleClickMakePayment={handleClickMakePayment}
      handleSetActive={handleSetActive}
      isSubmitting={isSubmitting}
      locales={locales}
      updateStatus={updateStatus}
    />
  )
}
