import React from "react"

import { withCustomerPaymentsListHeader } from "./withCustomerPaymentsListHeader"
import { Row, Column } from "../../../Styled/Table"

export const CustomerPaymentsListHeader = withCustomerPaymentsListHeader(({ columns, getValue }) => (
  <Row>
    {columns.map((column, index) => (
      <Column key={index} columns={columns.length} first={!column.center} firstRow noHighlight>
        {getValue(index)}
      </Column>
    ))}
  </Row>
))
