import React from "react"

import { withCustomerPayments } from "./withCustomerPayments"
import { Table } from "../../Styled/Table"
import { CustomerPaymentsListHeader } from "./List/CustomerPaymentsListHeader"
import { CustomerPaymentsSummary } from "./Summary/CustomerPaymentsSummary"
import { CustomerPaymentsList } from "./List/CustomerPaymentsList"
import { CustomerPaymentsListPlaceholder } from "./List/CustomerPaymentsListPlaceholder"
import { CustomerPaymentsItem } from "./Item/CustomerPaymentsItem"
import { CustomerPaymentsInformationPopup } from "./Popup/CustomerPaymentsInformationPopup"
import { CustomerPaymentsListLoading } from "./List/CustomerPaymentsListLoading"
import { CustomerPaymentsMethod } from "./Item/CustomerPaymentsMethod"
import { StyledContentBlock, StyledP, StyledLink, StyledHeading, StyledPromotionalText } from "./CustomerPaymentsStyles"
import { P } from "../../Styled/Text"

interface Props {
  plans: Array<any>
  onSelectePlan: any
  selectedOrder: object
  selectedPlan: object
  isLoading: boolean
  isUpdating: boolean
  reset: any
  updateStatus: any
  locales: any
  isLoadingSinglePlan: boolean
  showPopup: any
  setShowPopup: any
  customPaymentDescription: any
  customPaymentPolicy: any
  customerId: any
  onUpdate: any
  updateError: any
}

export const CustomerPayments = withCustomerPayments(
  ({
    selectedPlan,
    onSelectePlan,
    isLoading,
    isLoadingSinglePlan,
    plans,
    customerId,
    onUpdate,
    updateError,
    isUpdating,
    reset,
    updateStatus,
    locales,
    showPopup,
    setShowPopup,
    customPaymentDescription,
    customPaymentPolicy,
  }: Props) =>
    isLoading || isLoadingSinglePlan || (plans && plans.length > 0) ? (
      <>
        <Table>
          <CustomerPaymentsListHeader order locales={locales} />

          {!isLoading && !selectedPlan && plans && plans.length ? (
            <CustomerPaymentsList items={plans} onSelectePlan={onSelectePlan} />
          ) : !isLoading && selectedPlan ? (
            <CustomerPaymentsList items={[selectedPlan]} onSelectePlan={onSelectePlan} />
          ) : isLoading ? (
            <CustomerPaymentsListPlaceholder />
          ) : null}
        </Table>

        {isLoadingSinglePlan && !selectedPlan ? <CustomerPaymentsListLoading loading={isLoadingSinglePlan} /> : null}

        {!isLoading && selectedPlan && (
          <CustomerPaymentsSummary plan={selectedPlan} customerId={customerId} customPaymentPolicy={customPaymentPolicy} locales={locales} />
        )}

        {!isLoading && !isLoadingSinglePlan && (
          <StyledContentBlock>
            <StyledP>{locales.section2Line1}</StyledP>
            <P>{locales.section2Line2}</P>
            <StyledLink as={`a`} href={`mailto:enquiries@loveoneday.com.au`}>
              {locales.link2}
            </StyledLink>
          </StyledContentBlock>
        )}

        {!isLoading && selectedPlan && (
          <>
            <StyledHeading>{locales.sectionHeading3}</StyledHeading>
            <CustomerPaymentsListHeader transaction locales={locales} />
            <CustomerPaymentsList transaction items={selectedPlan.transactions} locales={locales} />

            <StyledHeading>{locales.sectionHeading4}</StyledHeading>
            {selectedPlan.order?.lineItems.edges.reduce((result, edge) => {
              if (edge.node.refundableQuantity > 0) {
                result.push(<CustomerPaymentsItem key={edge.node.id} item={edge.node} />)
              }
              return result
            }, [])}

            {selectedPlan?.paymentMethod && (
              <>
                <StyledHeading>{locales.sectionHeading5}</StyledHeading>
                <CustomerPaymentsMethod
                  paymentMethod={selectedPlan.paymentMethod}
                  onUpdate={onUpdate}
                  updateError={updateError}
                  isUpdating={isUpdating}
                  reset={reset}
                  updateStatus={updateStatus}
                  locales={locales}
                />
              </>
            )}
          </>
        )}
      </>
    ) : (
      <>
        <StyledPromotionalText onClick={() => setShowPopup(true)}>{`Learn more about onedayPAY`}</StyledPromotionalText>
        <CustomerPaymentsInformationPopup active={showPopup} setActive={setShowPopup} content={customPaymentDescription} />
      </>
    )
)
