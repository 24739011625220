import React from "react"

import { withCustomerPaymentsItem } from "./withCustomerPaymentsItem"
import { Image } from "../../../Image/Image"
import { Row, Col, ItemTitle, ItemOption } from "../CustomerPaymentsStyles"

interface Props {
  amount: any
  item: any
}

export const CustomerPaymentsItem = withCustomerPaymentsItem(({ amount, item }: Props) => (
  <Row>
    <Col>
      <Row condensed>
        <Col condensed>
          <Image src={item.product?.featuredImage?.originalSrc} />
        </Col>
        <Col>
          <ItemTitle>{item.title}</ItemTitle>
          {item.variant &&
            item.variant.selectedOptions &&
            item.variant.selectedOptions.map(option => (
              <ItemOption key={option?.name?.toString()}>
                {option.value?.toLowerCase().includes(`default title`) ? "" : `${option.name}: ${option.value}`}
              </ItemOption>
            ))}
        </Col>
      </Row>
    </Col>
    <Col>{amount ? amount : ""}</Col>
  </Row>
))
