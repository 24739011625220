import React, { useState } from "react"

export const withCustomerPaymentsMethod = Component => ({
  name = `CustomerPaymentsMethod`,
  isUpdating,
  locales,
  onUpdate,
  paymentMethod,
  reset,
  updateError,
  updateStatus,
}) => {
  const [modalActive, setModalActive] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = customerData => {
    let hasErrors = false
    if (!customerData.cardNumber || !customerData.cardName || !customerData.securityCode || !customerData.expirationDate) {
      setError(`Your payment details couldn’t be verified. Check your card details and try again.`)
      hasErrors = true
    } else {
      setError(false)
    }

    if (!hasErrors) {
      onUpdate(customerData)
    }
  }

  const handleModalActiveChange = () => {
    setModalActive(false)
    setError(false)
    reset()
  }

  Component.displayName = name
  return (
    <Component
      error={error}
      handleModalActiveChange={handleModalActiveChange}
      handleSubmit={handleSubmit}
      isUpdating={isUpdating}
      locales={locales}
      modalActive={modalActive}
      paymentMethod={paymentMethod}
      setModalActive={setModalActive}
      updateError={updateError}
      updateStatus={updateStatus}
    />
  )
}
