import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import axios from "axios"

import { useApp } from "@hooks/useApp"
import { useCore } from "@hooks/useCore"
import { useCustomerDetails } from "@hooks/useCustomer"
import { createBraintreeClient, updatePaymentMethod, usePaymentData } from "@hooks/usePayments"
import { useLanguage } from "@hooks/useLanguage"
import { useLocation } from "@hooks/useLocation"
import { Account } from "../../Account/Account"

export const withCustomerPayments = Component => ({ name = `CustomerPayments`, orderId, ...props }) => {
  const [plans, setPlans] = useState(null)
  const [isLoadingPlans, setIsLoadingPlans] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [isLoadingSinglePlan, setIsLoadingSinglePlan] = useState(false)
  const [customerId, setCustomerId] = useState(null)
  const [braintreeId, setBraintreeId] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const { shopifyStore } = useLocation()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { customPaymentDescription, customPaymentPolicy } = usePaymentData()
  const {
    config: {
      services: { onedaypay },
    },
  } = useApp()
  const { customer, loading: isLoadingCustomer } = useCustomerDetails()
  const locales = useLanguage(`payment`)

  const [braintreeClient, setBraintreeClient] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [updateError, setUpdateError] = useState(null)
  const [updateStatus, setUpdateStatus] = useState(null)

  useEffect(() => {
    const shopifyOrderId = orderId && parseInt(orderId) ? orderId : null
    const getCustomPaymentOrders = async () => {
      setIsLoadingPlans(true)
      const customerId = decodeShopifyId(customer.id, `Customer`)
      setCustomerId(customerId)
      return await axios
        .get(`${onedaypay.baseUrl}/api/customers/${customerId}/payment-plans`, {
          headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
        })
        .then(response => {
          return response.data
        })
    }

    const getSingleCustomPaymentOrder = async () => {
      setIsLoadingSinglePlan(true)
      const customerId = decodeShopifyId(customer.id, `Customer`)
      setCustomerId(customerId)
      return await axios
        .get(`${onedaypay.baseUrl}/api/customers/${customerId}/payment-plans/${shopifyOrderId}`, {
          headers: { "x-shopify-shop-domain": `${shopifyStore}.myshopify.com` },
        })
        .then(response => {
          return response.data
        })
    }

    if (customer && !isLoadingCustomer && !shopifyOrderId && shopifyStore) {
      getCustomPaymentOrders().then(response => {
        if (response.status === "success") {
          setPlans(response.body)
        }
        setIsLoadingPlans(false)
      })
    }

    if (customer && !isLoadingCustomer && shopifyOrderId && shopifyStore) {
      getSingleCustomPaymentOrder().then(response => {
        if (response.status === "success") {
          setPlans([response.body])
          setSelectedPlan(response.body)
          setBraintreeId(response.body.braintreeCustomerId)
        }
        setIsLoadingPlans(false)
        setIsLoadingSinglePlan(false)
      })
    }
  }, [customer, orderId, shopifyStore])

  useEffect(() => {
    if (!braintreeClient && braintreeId && shopifyStore) {
      const createBTClient = async () => {
        const client = await createBraintreeClient(braintreeId, shopifyStore)
        setBraintreeClient(client)
      }
      createBTClient()
    }
  }, [braintreeId, shopifyStore])

  const handleBackNavAction = () => {
    setSelectedPlan(null)
    navigate(`/account/onedaypay/overall/view`)
  }

  const handleSelectPlan = item => {
    setPlans([item])
    const orderId = item?.shopifyOrderId?.split(`Order/`)?.[1]
    navigate(`/account/onedaypay/${orderId}/view`)
  }

  const handleUpdatePaymentMethod = customerData => {
    setUpdateError(null)

    if (!shopifyStore) {
      setUpdateError(`Internal error. Looks like something went wrong on our end. Please try again later or contact our support team.`)
      return
    }

    setIsUpdating(true)
    const data = {
      creditCard: {
        number: customerData.cardNumber,
        cvv: customerData.securityCode,
        expirationDate: customerData.expirationDate,
        billingAddress: {
          postalCode: selectedPlan?.order.billingAddress.zip,
        },
      },
    }

    braintreeClient.request(
      {
        endpoint: "payment_methods/credit_cards",
        method: "post",
        data: data,
      },
      (err, response) => {
        if (!err) {
          const nonce = response.creditCards[0].nonce

          updatePaymentMethod(customerId, orderId, nonce, shopifyStore)
            .then(response => {
              setUpdateStatus(true)
              setIsUpdating(false)
              setSelectedPlan(response)
            })
            .catch(err => {
              console.error(`[ERROR] ${err.message}`)
              setIsUpdating(false)
              setUpdateStatus(false)
              setUpdateError(`[ERROR] ${err.message}`)
              return false
            })
        } else {
          console.error(`[ERROR] ${err}`)
          setIsUpdating(false)
          setUpdateStatus(false)
          setUpdateError(`Your payment details couldn’t be verified. Check your card details and try again.`)
          return false
        }
      }
    )
  }

  const handleReset = () => {
    setUpdateError(null)
    setUpdateStatus(null)
  }

  Component.displayName = name
  return (
    <Account
      {...props}
      description={
        isLoadingPlans || isLoadingCustomer || isLoadingSinglePlan || (plans && plans.length > 0) ? locales.pageHeading : locales.pageHeading2
      }
      title={locales.productLink1}
      showBackNav={!!selectedPlan || !isNaN(orderId)}
      onBackNavAction={handleBackNavAction}
      backNavText={locales.pageBackButton}
      left
    >
      <Component
        customPaymentDescription={customPaymentDescription}
        customPaymentPolicy={customPaymentPolicy}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        selectedPlan={selectedPlan}
        onSelectePlan={handleSelectPlan}
        isLoading={isLoadingPlans || isLoadingCustomer}
        isLoadingSinglePlan={isLoadingSinglePlan}
        plans={plans}
        customerId={customerId}
        isUpdating={isUpdating}
        onUpdate={handleUpdatePaymentMethod}
        updateError={updateError}
        reset={handleReset}
        updateStatus={updateStatus}
        locales={locales}
      />
    </Account>
  )
}
