import React from "react"

import { Popup } from "../../../Modals/Popup/Popup"
import { PrimaryButton } from "../../../Styled/Button"
import { Content, StyledP } from "../CustomerPaymentsStyles"

interface Props {
  active: boolean
  locales: any
  setActive: any
}

export const CustomerPaymentsConfirmPopup = ({ active, setActive, locales }: Props) => (
  <Popup active={active} setActive={setActive}>
    <Content>
      <StyledP>{locales.popupHeading2}</StyledP>
    </Content>

    <PrimaryButton onClick={() => setActive(false)}>{locales.popupButton2}</PrimaryButton>
  </Popup>
)
