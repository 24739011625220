import React from "react"

import { PAYMENT_PLAN_COLUMNS } from "../CustomerPaymentConsts"

import { Row, Column } from "../../../Styled/Table"
import { Placeholder } from "../CustomerPaymentsStyles"

export const CustomerPaymentsListPlaceholder = () => {
  const placeholders = []

  for (var i = 0; i < 5; i++) {
    placeholders.push(
      <Row key={i}>
        {PAYMENT_PLAN_COLUMNS.map((column, index) => (
          <Column key={index} columns={PAYMENT_PLAN_COLUMNS.length} first>
            <Placeholder />
          </Column>
        ))}
      </Row>
    )
  }

  return placeholders
}
