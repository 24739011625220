import React from "react"
import { Link } from "gatsby"

import { withCustomerPaymentsSummary } from "./withCustomerPaymentsSummary"

import { PrimaryButton } from "../../../Styled/Button"
import { Buttons, Button, StyledPrimaryButton, StyledP, StyledSpan, StyledHeading, TimelineButton, Message } from "../CustomerPaymentsStyles"
import { P } from "../../../Styled/Text"
import { CustomerPaymentsRequestPopup } from "../Popup/CustomerPaymentsRequestPopup"
import { CustomerPaymentsConfirmPopup } from "../Popup/CustomerPaymentsConfirmPopup"
import { CustomerPaymentsInformationPopup } from "../Popup/CustomerPaymentsInformationPopup"

export const CustomerPaymentsSummary = withCustomerPaymentsSummary(
  ({
    customerId,
    customPaymentPolicy,
    handleSubmit,
    locales,
    orderId,
    plan,
    price,
    paymentBalance,
    paymentPaid,
    setShowRequestPopup,
    setShowConfirmPopup,
    setShowTermsConditions,
    showRequestPopup,
    showConfirmPopup,
    showTermsConditions,
  }) => (
    <>
      <StyledHeading noConvertCase>{locales.sectionHeading1}</StyledHeading>

      <Message>{locales.section1Line1}</Message>

      {plan?.subscription ? (
        <>
          <P>
            {`${plan?.subscription?.numberOfBillingCycles} months `}
            <StyledSpan>{`(${plan?.subscription?.numberOfBillingCycles} payments of ${price})`}</StyledSpan>
          </P>
          <P>{`${locales.section1Line2} ${paymentPaid}`}</P>
          <StyledP>{`${locales.section1Line3} ${paymentBalance}`}</StyledP>
        </>
      ) : null}

      <Buttons>
        <Button>
          <PrimaryButton
            disabled={plan?.status == "Canceled" || plan?.status == "Expired"}
            as={Link}
            full={`true`}
            to={`/onedaypay/${orderId}/charge?id=${btoa(`customer_id=${customerId}&payment_type=additional&redirect_url=${window.location.href}`)}`}
          >
            {locales.button1}
          </PrimaryButton>
        </Button>
        <Button>
          <StyledPrimaryButton
            full={`true`}
            onClick={() => {
              setShowRequestPopup(true)
            }}
          >
            {locales.button2}
          </StyledPrimaryButton>
        </Button>
      </Buttons>

      <Message>
        {locales.section1Line4}
        <TimelineButton
          as={`button`}
          light={`${true}`}
          onClick={() => {
            setShowTermsConditions(true)
          }}
        >
          {locales.link1}
        </TimelineButton>
      </Message>

      <CustomerPaymentsRequestPopup active={showRequestPopup} setActive={setShowRequestPopup} onSubmit={handleSubmit} locales={locales} />
      <CustomerPaymentsConfirmPopup active={showConfirmPopup} setActive={setShowConfirmPopup} locales={locales} />
      <CustomerPaymentsInformationPopup active={showTermsConditions} setActive={setShowTermsConditions} content={customPaymentPolicy} />
    </>
  )
)
