import React from "react"

import { PAYMENT_PLAN_COLUMNS, PAYMENT_TRANSACTION_COLUMNS } from "../CustomerPaymentConsts"

export const withCustomerPaymentsListHeader = Component => ({ name = `CustomerPaymentsListHeader`, transaction, locales }) => {
  const columns = transaction ? PAYMENT_TRANSACTION_COLUMNS : PAYMENT_PLAN_COLUMNS

  const getValue = index => {
    return transaction ? locales[`transactionTableColumn${index + 1}`] : locales[`planTableColumn${index + 1}`]
  }

  Component.displayName = name
  return <Component columns={columns} getValue={getValue} />
}
