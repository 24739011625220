import React, { useState } from "react"

import { sendContribution } from "@hooks/usePayments"
import { useShopify } from "@hooks/useShopify"
import { useLocation } from "@hooks/useLocation"

export const withCustomerPaymentsSummary = Component => ({ name = `CustomerPaymentsSummary`, customerId, customPaymentPolicy, plan, locales }) => {
  const { formatMoney } = useShopify()
  const { shopifyStore } = useLocation()

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [showRequestPopup, setShowRequestPopup] = useState(false)
  const [showTermsConditions, setShowTermsConditions] = useState(false)

  const orderId = plan?.order?.id?.split(`Order/`)?.[1]
  const paymentPaid = formatMoney(parseFloat(plan?.total) - parseFloat(plan?.grossOutstanding))
  const paymentBalance = formatMoney(parseFloat(plan?.grossOutstanding))
  const price = formatMoney(plan?.subscription.price)

  const handleSubmit = async formData => {
    if (!shopifyStore) {
      return
    }

    const response = await sendContribution(
      customerId,
      orderId,
      formData.popupInput1, // name
      formData.popupInput2, // email
      formData.message,
      shopifyStore
    )
    setShowRequestPopup(false)
    setShowConfirmPopup(true)

    return response
  }

  Component.displayName = name
  return (
    <Component
      customerId={customerId}
      customPaymentPolicy={customPaymentPolicy}
      handleSubmit={handleSubmit}
      locales={locales}
      orderId={orderId}
      plan={plan}
      price={price}
      paymentBalance={paymentBalance}
      paymentPaid={paymentPaid}
      setShowConfirmPopup={setShowConfirmPopup}
      setShowRequestPopup={setShowRequestPopup}
      setShowTermsConditions={setShowTermsConditions}
      showConfirmPopup={showConfirmPopup}
      showRequestPopup={showRequestPopup}
      showTermsConditions={showTermsConditions}
    />
  )
}
