import React, { useState } from "react"

export const withCustomerPaymentsForm = Component => ({ name = `CustomerPaymentsForm`, locales, onSubmit, subjects, title }) => {
  const fields = [
    {
      key: "popupInput1",
      initialValue: "",
      required: true,
      type: "text",
    },
    {
      key: "popupInput2",
      initialValue: "",
      required: true,
      type: "email",
    },
    {
      key: "message",
      initialValue: "",
      required: true,
      type: "textarea",
      placeholder: "popupInput3",
    },
  ]

  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [data, setFormData] = useState({})

  const handleChange = ({ target: { name, value } }) => {
    setSent(false)
    setFormData(prevFormData => {
      const newFormData = {
        ...prevFormData,
      }
      newFormData[name] = value
      return newFormData
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    await onSubmit(data).then(() => {
      setFormData({})
      setLoading(false)
      setSent(true)
    })
  }

  Component.displayName = name
  return (
    <Component
      data={data}
      fields={fields}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      locales={locales}
      sent={sent}
      subjects={subjects}
      title={title}
    />
  )
}
