import React from "react"

import { withCustomerPaymentsUpdatePopup } from "./withCustomerPaymentsUpdatePopup"
import { Popup } from "../../../Modals/Popup/Popup"
import { PAYMENT_METHOD_FIELDS } from "../../../Checkout/Payment/CheckoutPaymentMethodConst"
import { CheckoutPaymentMethodField } from "../../../Checkout/Payment/CheckoutPaymentMethodField"
import { FormError, FormFields } from "../../../Styled/Form"
import { PrimaryButton } from "../../../Styled/Button"
import { CheckoutPaymentFooter } from "../../../Checkout/Payment/CheckoutPaymentFooter"
import { Content, StyledP, PaymentMethodFormFluidField } from "../CustomerPaymentsStyles"

interface Props {
  active: boolean
  customerData: any
  error: any
  handleChange: any
  handleClickMakePayment: any
  handleSetActive: any
  isSubmitting: any
  locales: any
  updateStatus: any
}

export const CustomerPaymentsUpdatePopup = withCustomerPaymentsUpdatePopup(
  ({ active, customerData, error, handleChange, handleClickMakePayment, handleSetActive, isSubmitting, locales, updateStatus }: Props) => (
    <Popup active={active} setActive={handleSetActive}>
      {updateStatus ? (
        <Content>
          <StyledP>{locales.popupHeading4}</StyledP>

          <PaymentMethodFormFluidField>
            <PrimaryButton full={`true`} onClick={() => handleSetActive()}>
              {locales.popupButton4}
            </PrimaryButton>
          </PaymentMethodFormFluidField>
        </Content>
      ) : (
        <Content>
          <StyledP>{locales.popupHeading3}</StyledP>

          <FormFields>
            {PAYMENT_METHOD_FIELDS.map(field => (
              <CheckoutPaymentMethodField
                error={error}
                key={field.key}
                customerData={customerData}
                field={field}
                handleChange={handleChange}
                locales={locales}
              />
            ))}
          </FormFields>

          <PaymentMethodFormFluidField>
            <PrimaryButton disabled={isSubmitting} full={`true`} onClick={() => handleClickMakePayment()}>
              {!isSubmitting ? locales.popupButton3 : locales.checkoutButton2}
            </PrimaryButton>
          </PaymentMethodFormFluidField>

          {error ? <FormError>{error}</FormError> : null}
        </Content>
      )}

      <CheckoutPaymentFooter condensed locales={locales} />
    </Popup>
  )
)
