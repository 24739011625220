import React from "react"
import { css } from "@emotion/core"
import BeatLoader from "react-spinners/BeatLoader"

import { StyledLoadingContainer } from "../CustomerPaymentsStyles"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`
export const CustomerPaymentsListLoading = ({ loading }) => (
  <StyledLoadingContainer>
    <div className="sweet-loading">
      <BeatLoader css={override} size={15} color={"#92979b"} loading={loading} />
    </div>
  </StyledLoadingContainer>
)
