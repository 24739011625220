import React from "react"

import { Popup } from "../../../Modals/Popup/Popup"
import { CustomerPaymentsForm } from "../Form/CustomerPaymentsForm"
import { Content, StyledP, StyledSpan } from "../CustomerPaymentsStyles"

interface Props {
  active: boolean
  onSubmit: any
  locales: any
  setActive: any
}

export const CustomerPaymentsRequestPopup = ({ active, setActive, onSubmit, locales }: Props) => (
  <Popup active={active} setActive={setActive}>
    <Content>
      <StyledP>{locales.popupHeading1}</StyledP>

      <CustomerPaymentsForm locales={locales} onSubmit={onSubmit} />
    </Content>

    <StyledP>
      <StyledSpan>{locales.popupFooter1}</StyledSpan>
    </StyledP>
  </Popup>
)
