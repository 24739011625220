export const PAYMENT_METHOD_FIELDS = [
  {
    id: "card-number",
    key: "cardNumber",
    default: true,
    label: "",
    placeholder: "popupInput4",
    required: true,
    type: "number",
    maxchars: 19,
  },
  {
    id: "card-name",
    key: "cardName",
    default: true,
    label: "",
    placeholder: "popupInput5",
    required: true,
    type: "text",
  },
  {
    id: "expiration-date",
    key: "expirationDate",
    initialValue: "",
    label: "",
    placeholder: "popupInput6",
    required: true,
    width: "1/2",
    type: "date",
  },
  {
    id: "cvv",
    key: "securityCode",
    initialValue: "",
    label: "",
    placeholder: "popupInput7",
    required: true,
    width: "1/2",
    type: "number",
    maxchars: 4,
  },
]
